
<template lang="pug">
.wrapper.i-wrap
  .section-left
  transition(name="slideup")
    .section-mid(v-if="!isLoading")
      .search-wrap(v-if="movies.length > 0")
        h3.sectionTitle 電影
        .items
          .item(v-for="movie in movies")
            SearchResult(
              type="movie"
              :result='movie'
            )
          p.pointer(v-if="movies.length > 9" @click="seeMore('movie')") 更多
      .search-wrap(v-if="stars.length > 0")
        h3.sectionTitle 明星
        .items
          .item(v-for="star in stars")
            SearchResult(
              type="star"
              :result='star'
            )
          p.pointer(v-if="stars.length > 9" @click="seeMore('star')") 更多
      .search-wrap(v-if="tvSeries.length > 0")
        h3.sectionTitle 電視劇
        .items
          .item(v-for="tv in tvSeries")
            SearchResult(
              type="tvSeries"
              :result='tv'
            )
          p.pointer(v-if="tvSeries.length > 9" @click="seeMore('tvSeries')") 更多
  .section-mid(v-if="isLoading")
    Spinner
  .section-right

</template>

<script>
import SearchResult from '@/components/SearchResult';
import Spinner from 'vue-simple-spinner';
export default {
  name: 'Search',
  components:{
    SearchResult,
    Spinner
  },  
  metaInfo() {
    var x = this.isLoading;
    var theName = '';
    theName = this.searchQuery
    return {
      title: this.isLoading ? 'Loading...': theName,
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }    
  },
  data(){
    return{
      isLoading:true,
      searchQuery:'',
      stars:[],
      movies:[],
      tvSeries:[],
    }
  },
  
  methods:{
    async seeMore(type){
      await this.$router.push({'path':`/advancedSearch?query=${this.searchQuery}&type=${type}&page=1`}).catch(err=>{
      })
    }
  },
  async created(){
    this.searchQuery = this.$route.query.query;
    var results = await this.axios(`/api/search?query=${this.searchQuery}`)
    this.movies = results.data.movies
    this.stars = results.data.stars
    this.tvSeries = results.data.tvSeries
    this.isLoading = false;
  },
}
</script>
<style lang="scss" scoped>
  .search-wrap{
    display:flex;
    flex-direction: column;
  }
  h3{
    display:inline-block;
    margin:0 auto;
  }
  p{
    text-align:right;
  }
</style>
